function resizeImages() {
  $('.js-image-responsive').each(function(i, image) {
    const width = $(image).parent().width().toFixed(0);
    let height = $(image).hasClass('js-image-square') ? width : $(image).parent().height().toFixed(0)
    height = $(image).hasClass('js-image-4-3') ? (width * 3 / 4).toFixed(0) : height
    const src = $(image).attr('data-src').replace(/block-\d+-\d+/, `block-${width}-${height}`)
    $(image).attr('src', src);
    $(image).on('load', function(image) {
      $(this).addClass('image-show')
    });
  });
}

$(function() {
  // Resize images on window resize
  $(window).resize(function() {
    clearTimeout(this.deferred)
    this.deferred = setTimeout(resizeImages, 100)
  })

  resizeImages();
})
